* {
  text-decoration: none !important;
}

.form-select {
  --bs-form-select-bg-img: url(../src/assets/images/svg/bttom_arrw.svg) !important;
  background-size: 10px !important;
}

.custom_border {
  border: 1px solid rgba(39, 42, 50, 1);
}

.outline_none {
  outline: none !important;
}

.intrest_bg_image {
  background-image: url(../src/assets/images/png/intrest_bg_image.png);
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh !important;
}

/* ------------------------------------- */

.check_box_input .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.check_box_input {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 22px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check_box_input input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: transparent;
  border: 2px solid #22b264;
  border-radius: 100px;
}

.check_box_input:hover input ~ .checkmark {
  background-color: #ccc;
}

.check_box_input input:checked ~ .checkmark {
  border: 2px solid #fff;
  background-color: transparent !important;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.check_box_input input:checked ~ .checkmark:after {
  display: block;
}

.custom_border1 {
  border: 1.4px solid rgba(145, 145, 145, 1);
}

.green_border {
  border: 2px solid rgba(34, 178, 100, 1);
}

.modal-content {
  background-color: transparent !important;
}

.bg_modal {
  background: rgba(39, 42, 50, 0.53);
}

.deposit ::placeholder {
  color: white !important;
  font-weight: 500 !important;
}
