.yellow_linear {
  background: linear-gradient(102.99deg, #ddbf41 13.76%, #e7ab08 85.59%);
}

.blue_linear {
  background: linear-gradient(102.69deg, #8064f2 37.9%, #7b9aec 96.62%);
}

.matches-div {
  background: linear-gradient(
    85.77deg,
    #1d393a 8.66%,
    #141b2e 86.73%
  ) !important;
  min-height: 200px;
}
.matches-div2 {
  background: linear-gradient(
    85.77deg,
    #1d393a 8.66%,
    #141b2e 86.73%
  ) !important;
}

.welcome_card {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.06) 0%,
    rgba(153, 153, 153, 0.06) 100%
  );
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.text-orange {
  color: #ffaf3f;
}

.get_btn {
  background: linear-gradient(94.84deg, #49db64 -9.54%, #28b811 95.53%);
}

.color_green {
  color: #49db64;
}
