.score-broadcast-div {
  border: 1px solid var(--clr-green);
  background-color: var(--clr-dark-blue-2);
  border-radius: 5px;
  height: 43px;
}
.btn-radius {
  border-radius: 5px;
}

/* MATCH ODDS */
.accordion-button::after {
  flex-shrink: 0;
  margin-left: 12px;
  background-image: url("../../assets/images/svg/accordionArrow.svg") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 11px 11px;
}
.fancy .accordion-button::after {
  margin-left: auto;
}
.accordion-button:not(.collapsed) {
  background-color: var(--clr-green);
  color: white;
  box-shadow: unset;
}
.accordion-button:focus {
  box-shadow: unset;
}
.accordion-button {
  background-color: var(--clr-green);
  color: white;
}
.accordion-item {
  color: white;
  background-color: var(--clr-dark-grey-3);
  border: unset;
}
.first-last-price-div {
  background-color: var(--clr-blue);
  border-radius: 2px;
  width: 48px;
  height: 44px;
}
.blue-clr-div {
  border-radius: 2px;
  width: 48px;
  background-color: var(--clr-light-blue);
  height: 44px;
}
.pink-clr-div {
  border-radius: 2px;
  width: 48px;
  background-color: var(--clr-pink);
  height: 44px;
}
.match-odds-hr-line {
  height: 1px;
  background-color: var(--clr-dark-grey-6);
}
