@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100..900&family=Inter:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  list-style-type: none !important;
  text-decoration: none !important;
}

body {
  background-color: var(--clr-dark-blue-2) !important;
  font-family: "Inter", sans-serif;
}

.ff_inter {
  font-family: "Inter", sans-serif;
}

.ff_heebo {
  font-family: "Heebo", sans-serif;
}

.ff_roboto {
  font-family: "Roboto", sans-serif;
}

:root {
  --fs-6xsm: 8px;
  --fs-5xsm: 9px;
  --fs-4xsm: 10px;
  --fs-3xsm: 11px;
  --fs-2xsm: 12px;
  --fs-xxsm: 13px;
  --fs-xsm: 14px;
  --fs-sm: 15px;
  --fs-md: 16px;
  --fs-lg: 17px;
  --fs-xl: 18px;
  --fs-xxl: 19px;
  --fs-2xl: 22px;
  --fs-3xl: 24px;
  --fs-4xl: 27px;
  --fs-5xl: 28px;
  --fs-6xl: 36px;
  --fs-7xl: 48px;

  /* COLORS */

  --clr-green: #22b264;
  --clr-red: #e9113c;
  --clr-dark-blue: #010039;
  --clr-dark-blue-2: #12151e;
  --clr-grey: #8a8a8a;
  --clr-grey-2: #757575;
  --clr-grey-3: #8b8b8b;
  --clr-dark-grey: #767676;
  --clr-dark-grey-2: #484848;
  --clr-dark-grey-3: #272a32;
  --clr-dark-grey-4: #595959;
  --clr-dark-grey-5: #464951;
  --clr-dark-grey-6: #3e434f;
  --clr-blue: #5a8bae;
  --clr-light-blue: #72bbef;
  --clr-pink: #f994ba;
  --clr-soil: #fff2f2;
}

.fs_7 {
  font-size: var(--fs-6xsm);
}

.fs_9 {
  font-size: var(--fs-5xsm);
}

.fs_10 {
  font-size: var(--fs-4xsm);
}

.fs_11 {
  font-size: var(--fs-3xsm);
}

.fs_12 {
  font-size: var(--fs-2xsm);
}

.fs_13 {
  font-size: var(--fs-xxsm);
}

.fs_14 {
  font-size: var(--fs-xsm);
}

.fs_15 {
  font-size: var(--fs-sm);
}

.fs_16 {
  font-size: var(--fs-md);
}

.fs_17 {
  font-size: var(--fs-lg);
}

.fs_18 {
  font-size: var(--fs-xl);
}

.fs_19 {
  font-size: var(--fs-xxl);
}

.fs_22 {
  font-size: var(--fs-2xl);
}

.fs_24 {
  font-size: var(--fs-3xl);
}

.fs_27 {
  font-size: var(--fs-4xl);
}

.fs_28 {
  font-size: var(--fs-5xl);
}

.fs_36 {
  font-size: var(--fs-6xl);
}

.fs_48 {
  font-size: var(--fs-7xl);
}


.clr_yellow {
  color: #FFAF3F;
}

/* COLORS */
.clr_soil {
  color: var(--clr-soil);
}

.clr_blue {
  color: var(--clr-dark-blue);
}

.clr_grey {
  color: var(--clr-grey) !important;
}

.clr_grey_3 {
  color: var(--clr-grey-3);
}

.clr_grey_2 {
  color: var(--clr-grey-2);
}

.clr_dark_blue {
  color: var(--clr-dark-blue);
}

.clr_dark_blue_2 {
  color: var(--clr-dark-blue-2);
}

.clr_dark_grey {
  color: var(--clr-dark-grey);
}

.clr_red {
  color: var(--clr-red);
}

.bg_clr_red {
  background-color: var(--clr-red);
}

.clr_green {
  color: var(--clr-green);
}

.bg_clr_green {
  background-color: var(--clr-green);
}

.bg_clr_pink {
  background-color: var(--clr-pink);
}

.bg_clr_blue {
  background-color: var(--clr-blue);
}

.bg_clr_light_blue {
  background-color: var(--clr-light-blue);
}

.bg_clr_dark_grey_2 {
  background-color: var(--clr-dark-grey-2);
}

.bg_clr_dark_grey_3 {
  background-color: var(--clr-dark-grey-3) !important;
}

.bg_clr_dark_grey_4 {
  background-color: var(--clr-dark-grey-4);
}

.bg_clr_dark_blue_2 {
  background-color: var(--clr-dark-blue-2);
}

/* FONT WEIGHTS */
.fw-900 {
  font-weight: 900;
}

.fw-800 {
  font-weight: 800;
}

.slick-dots li {
  margin: 0 !important;
}

.range{
  height: 9px !important;
}

.right-0{
  right: 0px !important;
}

.slick-dots li button:before {
  font-size: 7px !important;
  opacity: 0.55 !important;
  color: white !important;
}

.slick-dots li.slick-active button:before {
  font-size: 7px !important;
  opacity: 0.75 !important;
  color: #22b264 !important;
}

.logoutbtn {
  box-shadow: 0px -1px 12px 2px #c7bdbd12;
}

.cursor-pointer {
  cursor: pointer;
}


.vh-90{
  height: 92vh !important;
}
/* BOTTOM NAVBAR */

.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.home-bg {
  background: #22b2641c;
  height: 55px;
  width: 55px;
  border-radius: 100%;
}

.btn-close {
  filter: invert(1);
}

.z-100 {
  z-index: 100;
}

.home-img-shadow {
  filter: drop-shadow(0px 2.53px 4.73px #00000040);
}

.bottom-nav-shadow {
  box-shadow: 0px 4px 18.6px 0px #000000;
}

/* WITHDRAW PAGE */
.withdraw::placeholder {
  color: white;
  opacity: 1;
}

.StepButton-d0-0-2-6.completed {
  background-color: #22b264 !important;
}
.StepButton-d10-0-2-38.completed {
  background-color: #22b264 !important;
}
.StepButton-d2-0-2-12.active {
  background-color: #22b264 !important;
}
.StepButton-d12-0-2-42.active {
  background-color: #22b264 !important;
}

select {
  background-image: url("/src/assets/images/svg/greenDownArrow.svg") !important;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) 22px, calc(100% - 20px) 1em, 100% 0;
}

select {
  appearance: none !important;
}

/* FACEBOOK GOOGLE LOGIN BUTTON */
.kep-login-facebook {
  border: 1px solid var(--clr-dark-grey-3) !important;
  background-color: transparent !important;
  border-radius: 4px !important;
  height: 52px !important;
  padding: 0 !important;
  width: 100%;
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
  font-size: var(--fs-xxsm) !important;
  text-transform: unset !important;
}

.gameLoading {
  min-height: "80vh";
  min-width: "80vw";
  margin: "auto";
}

.suspeded-overlay{
   background-color: rgba(16, 16, 16, 0.738) !important;
   border-radius: 2px;
}

.overlay-div{
  height: 44px;
}

.ripple {
  position: relative;
  overflow: hidden;
}

.ripple::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: rgba(200, 8, 8, 0.6);
  border-radius: 50%;
  transform: scale(0);
  animation: ripple-effect 0.4s ease-out forwards;
  pointer-events: none;
}

@keyframes ripple-effect {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}



.gameCard {
  min-height: "100px";
  min-width: "100px";
}

.gameTitle {
  min-height: "10px";
  min-width: "100px";
  margin-top: "12px";
}

.gameTitle2 {
  min-height: "10px";
  min-width: "50px";
  margin-top: "6px";
}

.search-icon {
  background-color: #404552;
}

.skeleton {
  background-color: #4f5559;
  background-image: linear-gradient(90deg,
      rgba(255, 255, 255, 0),
      rgba(124, 123, 127, 0.5),
      rgba(255, 255, 255, 0));
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s infinite;
}

/* input[type="range" i]:disabled {
  background-color: #22b264 !important;
  color: #22b264 !important;
} */
.range {
  width: 30vw;
  height: 9px !important;
}


.intercom-namespace .intercom-with-namespace-52k34s {
  user-select: none;
  z-index: 2147483000;
  position: fixed;
  bottom: 123px !important;
  right: 20px;
  width: 48px;
  height: 48px;
  transition: transform 167ms cubic-bezier(0.33, 0, 0, 1) 0s;
  transform-origin: center center;
}


.intercom-lightweight-app-launcher {
  position: fixed;
  z-index: 2147483003;
  padding: 0 !important;
  margin: 0 !important;
  border: none;
  bottom: 123px !important;
  right: 20px;
  max-width: 48px;
  width: 48px;
  max-height: 48px;
  height: 48px;
  border-radius: 50%;
  background: #0071b2;
  cursor: pointer;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.06), 0 2px 32px 0 rgba(0, 0, 0, 0.16);
  transition: transform 167ms cubic-bezier(0.33, 0.00, 0.00, 1.00);
  box-sizing: content-box;
}

.intercom-lightweight-app-launcher-icon {
  background-color: var(--clr-green) !important;
  border-radius: 100% !important;
}
.intercom-1caczyq {
  background-color: var(--clr-green) !important;
  border-radius: 100% !important;
}

.intercom-zi3hra {
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgb(0, 113, 178) !important ;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  backface-visibility: hidden;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
}

.lay-border{
  border-left: 4px solid #0071b2;
}

.back-border{
  border-left: 4px solid #f994ba;
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}