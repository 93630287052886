.green-hr-line {
  width: 63px;
  height: 5px;
  border-radius: 39px;
}
.bet-slip {
  bottom: -800%;
  left: 0;
  z-index: 4;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
}
.bet-slip-active {
  bottom: 0% !important;
  /* min-height: 80vh; */
  overflow: auto;
}
.ticket-br-radius {
  border-radius: 2px;
}
.event-status-bg {
  width: 40px;
  clip-path: polygon(0 0, 80% 0%, 100% 100%, 0% 100%);
}
.events-hr-line {
  height: 0.5px;
  background-color: var(--clr-dark-grey-6);
}
.bet-placing-div {
  height: 25px;
}
.selected-price {
  border: 1px solid #ffffff;
}
.body-overlay {
  width: 100%;
  height: 100vh;
  opacity: 1;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  backdrop-filter: blur(6px) saturate(40%) contrast(45%) brightness(100%);
  background-color: rgb(6, 105, 52, 40%);
  -webkit-backdrop-filter: blur(20px) saturate(160%) contrast(45%)
    brightness(140%);
}

/*  PROFILE */
.profile-sec-hr-line {
  height: 1px;
  background-color: #1e2129;
}
