/* NAVBAR */

.bar {
  display: block;
  width: 25px;
  height: 2.5px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 6px;
}
.text_x {
  background: linear-gradient(186deg, #fff 30.47%, #fff 55.11%, #b6b6b6 79.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 2px;
}

.body-overlay {
  width: 100%;
  height: 100vh;
  opacity: 1;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 4;
  backdrop-filter: blur(6px) saturate(40%) contrast(45%) brightness(100%);
  background-color: rgb(6, 105, 52, 40%);
  -webkit-backdrop-filter: blur(20px) saturate(160%) contrast(45%)
    brightness(140%);
}
.body-overlay-false {
  left: -100%;
}
.nav-hidden {
  position: fixed;
  left: -100%;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--clr-dark-blue-2);
  width: 80%;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
  z-index: 4;
}
.nav-open {
  background-color: var(--clr-dark-blue-2);
  position: fixed;
  left: 0;
  top: 0;
  gap: 0;
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
  z-index: 4;
}
.search-input-div {
  height: 47px;
}
.search-input {
  outline: none;
}
.opacity-26 {
  opacity: 26%;
}

.price_container {
  background-color: #272a32;
  min-width: 96.86px;
  min-height: 30.61px;
}

.currency {
  color: #a3b1cc;
}
/* HOME HERO */
.hr-line {
  height: 18px;
  width: 1.5px;
  border-radius: 1.5px;
}
.btn-bet-now {
  box-shadow: 0px -1.61px 7.57px 0px #00000040;
  left: 50%;
  bottom: 12%;
  transform: translateX(-50%);
  padding: 8px 10px;
}

/* CATEGORIES */
.all-sports-games-div {
  max-width: 620px;
  overflow-x: auto;
  overflow-y: hidden;
}
.sports-games-box {
  width: 90px;
  height: 90px;
}
.sports-casino-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}
.sports-casino-scrollbar::-webkit-scrollbar-track {
  background: var(--clr-dark-grey-3);
  border-radius: 2px;
}
.sports-casino-scrollbar::-webkit-scrollbar-thumb {
  background: linear-gradient(94.84deg, #49db64 -9.54%, #28b811 95.53%);

  border-radius: 2px;
}
.sports-casino-scrollbar::-webkit-scrollbar-thumb:hover {
  background: var(--clr-green);
}

.bg_gray_linear {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.09) 0%,
    rgba(153, 153, 153, 0) 100%
  );
}

/* EVENTS */
.events-div {
  min-width: 400px;
}
.events {
  background: var(--clr-dark-grey-3);
  height: 42px;
  border-radius: 55px;
  width: 100%;
}
.active-event {
  background: var(--clr-green);
  height: 42px;
  border-radius: 55px;
  width: 100%;
}

.events-scollbar::-webkit-scrollbar {
  display: none;
}

/* CRICKET EVENTS */
.matches-box-div::-webkit-scrollbar {
  display: none;
}
.matches-div {
  background-color: var(--clr-dark-grey-3);
  min-width: 310px;
}
.events-hr-line {
  height: 0.5px;
  background-color: var(--clr-dark-grey-5);
}
.betting-info-div {
  height: 60px;
}

/* LETS TALK */
.lets-talk-div {
  bottom: 20%;
  right: 10%;
}
.lets-talk-white-div {
  width: 120px;
  border-radius: 36px;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 12.3px 0px #00000040;
}
.customer-suppport-bg {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  box-shadow: 0px 6px 26.7px 0px #22b264;
  border: 1px solid #ffffff;
  top: 50%;
  right: -20%;
  transform: translateY(-50%);
}
